import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image = require("../../../assets/img/blogs/top_itsm_2024_blog_header.png");
const section_1 = require("../../../assets/img/blogs/eisdm_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/eisdm_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/eisdm_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/eisdm_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/eisdm_blog_img_5.png");
const section_6 = require("../../../assets/img/blogs/eisdm_blog_img_6.png");
const section_7 = require("../../../assets/img/blogs/eisdm_blog_img_7.png");
const section_8 = require("../../../assets/img/blogs/eisdm_blog_img_8.png");
const section_9 = require("../../../assets/img/blogs/eisdm_blog_img_9.png");
const section_10 = require("../../../assets/img/blogs/eisdm_blog_img_10.png");
const section_11 = require("../../../assets/img/blogs/eisdm_blog_img_11.png");
const section_12 = require("../../../assets/img/blogs/eisdm_blog_img_12.png");
const section_13 = require("../../../assets/img/blogs/eisdm_blog_img_13.png");
const section_14 = require("../../../assets/img/blogs/eisdm_blog_img_14.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Employee IT Service Desk Metrics: Which KPIs Should We Measure?"
        description="Discover the essential KPIs for measuring employee IT service desk performance and optimize your support operations with key metrics"
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section
            className={`w-100 float-left blog_top_header_upt eisdm_blog_header_upt ${
              isSmall ? "align-items-center" : null
            }`}
          >
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile
                    ? "text-align-center mt-0"
                    : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-page-header-home-blog-employee color-black line-height-18">
                    <span
                      style={{ backgroundColor: "#FFFA7E" }}
                      className="blog-text-color-padding"
                    >
                      Employee IT Service Desk
                    </span>
                    Metrics: Which KPIs Should We Measure
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog-employee color-black">
                    <span
                      style={{ backgroundColor: "#FFFA7E" }}
                      className="blog-text-color-padding"
                    >
                      Employee IT Service Desk
                    </span>
                    <br />
                    Metrics: Which KPIs Should
                    <br /> We Measure
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. What are help desk or IT Service Desk KPIs or Metrics?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Are help desk metrics and key performance indicators the
                  same?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Why are IT service desk metrics important for your
                  business?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. Incident Management Metrics
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. Service Request Metrics
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. Agent Productivity and Workload Metrics
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. How to improve IT service desk metrics or KPIs
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section8"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section8"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section8")}
                >
                  8. How Workativ is committed to providing benefits to improve
                  your IT service desk metrics
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section9"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section9"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section9")}
                >
                  9. FAQs
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Small or big — companies’ success relies upon{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/ai-it-service-management">
                  IT service management.
                </a>{" "}
                Simultaneously, the success of ITSM significantly depends upon
                tracking and working on{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  IT service desk metrics
                </span>{" "}
                — or, to essentially,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  measure service desk KPIs.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is essential to track a variety of IT service desk KPIs or
                Key Performance Indicators to meet service desk KPIs and meet
                stakeholders' expectations or overall business goals.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-automation">
                  service desk
                </a>{" "}
                allows you to implement effective steps to manage service
                delivery.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Conversely, capturing service desk metrics or KPIs helps you
                gauge IT support teams’ performance and implement best
                practices.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s learn what these essential KPIs of Service Desk Metrics
                are.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                What are help desk metrics?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                This is a simple way to look at how your service desk performs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To understand this, you would probably consider some attributes
                that contribute to elevating your service desk’s performance and
                user satisfaction.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A simple definition would be: help desk metrics are measuring
                attributes to examine the performance and effectiveness of the
                service desk towards customer service or employee support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                These KPIs provide efficiency and effectiveness metrics,
                allowing organizations to detect and improve performance gaps to
                optimize{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/transform-service-desk-with-it-chatbot">
                  service desk operations.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                They are indicators for{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/transform-service-desk-with-it-chatbot">
                  service desk software
                </a>{" "}
                to help IT managers determine how a service desk efficiently
                aligns with the business objectives by delivering services for
                users to solve problems and improve uptime.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Considering{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  time to resolution
                </span>{" "}
                (TTR) as a small example, it could act as a service desk metric
                or KPI for IT managers to understand what time a service desk
                takes to resolve an issue. Based on the outcomes, an IT manager
                can suggest improvements.
              </p>
            </div>

            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Are help desk metrics and key performance indicators the same?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Help desk metrics or KPIs may sound similar but vary widely.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In a more specific way, service desk metrics involve performance
                metrics associated with service desks—that is, how well a
                service desk team is performing to assist the requesters.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Or how does a service desk contribute to resolving a particular
                user problem?
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                The other way around, service desk metrics connect dots to
                facilitate KPIs to help gain a bigger organizational goal.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                In a nutshell, we can say — service desk metrics are integral to
                service desk KPIs that bring organizational resilience and
                growth through customer service delivery by adhering to user
                needs and meeting user expectations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Say your{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/helpdesk-problems-solutions">
                  service desk agents improve help desk responses
                </a>{" "}
                by reducing ticket volume for a specific period. This metric
                depicts an organizational goal of resolving as many user
                problems as possible while ensuring user experience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why are IT service desk metrics important for your business?
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="IT service desk benefits"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Service desk metrics or KPIs are essential to ensure you drive
                business success in several ways.
              </p>

              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Cost reduction:&nbsp;
                  </span>
                  Real-time service delivery confirms that your users get what
                  they want in time and helps reduce costs that are otherwise
                  common in case problems are recurring and frequent.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Improved team productivity:&nbsp;
                  </span>
                  Service desk metrics exhibit the areas for improvement for
                  your teams. IT managers can look into this and work on these
                  areas to bring effective practice that helps boost the team’s
                  performance.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Elevated user experience:&nbsp;
                  </span>
                  IT helps desk metrics can help you visualize what went wrong
                  for your service desk while delivering a service. Eliminating
                  these loopholes can improve service desk performance abilities
                  and meet user experience.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Improved service desk decisions:&nbsp;
                  </span>
                  With service desk metrics, IT managers can improve
                  decision-making and visualize the necessary resources needed
                  to tackle some instances in different scenarios, helping the
                  service desk improve its responses.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Driving business growth:&nbsp;
                  </span>
                  Based on IT help desk metrics, businesses can get to know
                  where things are not working and implement the best way to
                  tackle adversaries. This allows companies to meet business
                  goals and drive success.
                </li>
              </ul>
            </div>
            <h2 className="font-section-sub-header-bold">
              Crucial service desk metrics or KPIs we should measure
            </h2>
            <img
              src={section_2}
              className="blog_image_top_bt"
              alt="Significant service desk KPIs"
            />
            <p className="font-section-normal-text-testimonials line-height-18 ">
              Service desk or{" "}
              <a href="https://workativ.com/conversational-ai-platform/blog/ai-it-service-management">
                IT service management
              </a>{" "}
              is a broader aspect of IT service operations. As per ITIL, it
              involves a massive area to look upon and correct if these
              attributes are underperforming.
            </p>
            <p className="font-section-normal-text-testimonials line-height-18 ">
              We’ve divided IT help desk metrics into three significant groups
              to make it look easy for you and take the necessary action to
              improve their efficiencies.
            </p>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Incident Management Metrics
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Incident management is crucial, as it combines several metric
                data points to address challenges and ensure an efficient
                service desk.
              </p>
              <h3 className="font-section-sub-header-small-home">
                1. Ticket volume{" "}
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The number of tickets or service requests a service desk handles
                in a particle period is known as ticket volume.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                It signifies how efficiently a service desk team handles service
                requests. If the ticket volume grows, it can directly point to
                service desk inefficiency, indicating that a team may require
                adequate resources for a timely response.
              </p>
              <h4 className="font-section-normal-text-medium">
                How to calculate ticket volume:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                This requires you to monitor a certain period, in which you must
                count how many tickets your service desks receive.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="service desk KPIs Ticket Volume"
              />
              <h3 className="font-section-sub-header-small-home">
                2. Ticket backlog
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The term backlog is easier to understand, which means unresolved
                tickets are getting heaped over due to inattention.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                This shows that a service desk has limited resources or skills
                to handle timely requests, causing ticket backlogs.
              </p>
              <h4 className="font-section-normal-text-medium">
                How to calculate ticket backlog:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Take a specific period for both tickets opened and closed.
                Subtract the open tickets at the beginning and closed tickets at
                the end of the week. This gives you the exact value of how many
                tickets got backlogged.
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt=" Service Desk KPIs Ticket backlog"
              />
              <h3 className="font-section-sub-header-small-home">
                3. Escalation rate
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                With a percentage of tickets being escalated to the next tier
                without the proper resources or skills of the first contact, the
                transfer of tickets to the next tier is known as the escalation
                rate in the service desk ecosystem.
              </p>

              <h4 className="font-section-normal-text-medium">
                How to calculate escalation rate:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Take the number of tickets escalated to the next tier and divide
                it by the total number of tickets received. To get a percentage,
                multiply it by 100.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt=" service desk KPIs Escalation Rate Percentage  "
              />
              <h3 className="font-section-sub-header-small-home">
                4. Incident prioritization
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                An incident could be of many types. However, not every case
                constitutes a severe incident for a service desk, and they can
                get immediate help.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Response and service delivery depend on the severity of a case.
                A service desk generally bases its priority on low, medium,
                high, and critical incidents.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Instead of doing it manually, an AI-based service desk can help
                you gauge incident prioritization and build a strategy for
                tackling incidents.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Service Request Metrics
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                In determining service desk metrics, you can prefer capturing
                data through various attributes of service request metrics. They
                are stated below:
              </p>
              <h3 className="font-section-sub-header-small-home">
                1. Customer satisfaction score (CSAT)
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                One of the best key performance indicators for{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/roi-chatbot-automation-it-service-desk">
                  IT service desk performance
                </a>{" "}
                improvement is CSAT.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                It is not at all rocket science. All it takes is to ask your
                customers or service desk users how they feel about the service
                responses they receive.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Usually, the CSAT metric is captured on a scale of 1 to 5 or 1
                to 10, and the CSAT feedback survey can ask you to choose any
                options from very satisfied to very unsatisfied.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If your CSAT score is higher, your users will be happy with your
                service quality. The opposite is true if your CSAT score is low.
              </p>
              <h4 className="font-section-normal-text-medium">
                How to calculate the CSAT rate:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Calculate CSAT every quarter or month to have enough time to
                improve your customer satisfaction score with your service desk.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Divide the CSAT score you collected by the total customer
                responses and multiply it by 100.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="Service desk KPIs CSAT"
              />
              <h3 className="font-section-sub-header-small-home">
                2. Customer effort score or CES
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The word ‘effort’ in CES says it all.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                How much effort a user needs to exert while trying to solve a
                problem through a service desk is all that is a metric that
                defines customer effort score.
              </p>
              <h4 className="font-section-normal-text-medium">
                How to calculate CES:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Three simple ways constitute how CES must be measured —
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-normal-text-testimonials">
                  Word-based scale
                </li>
                <li className="font-section-normal-text-testimonials">
                  Numerical scale
                </li>
                <li className="font-section-normal-text-testimonials">
                  Sentiment scale
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                On a scale of ‘very easy’ or ‘very difficult’, the customer
                effort score can be measured.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, you can choose any of these methods to calculate CES and
                divide the sum of CES scores by the total responses collected.
                To get a percentage, multiply it by 100.
              </p>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="Service desk KPIs CES"
              />
              <h3 className="font-section-sub-header-small-home">
                3. Net promoter score (NPS)
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is another effective service desk KPI to measure the
                effectiveness of your IT help desk.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With ecommerce interactions, customers are more likely to ask,
                ‘How likely are you eager to recommend our service to others?’
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On a scale of 0-10, which focuses primarily on two key metrics,
                highly and never, are used to know user loyalty and advocacy. In
                the language of NPS, users who recommend your service desk to
                others are recognized as promoters, and those who don’t are
                known as detractors.
              </p>
              <h4 className="font-section-normal-text-medium">
                How to calculate NPS:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is easy to measure the NPS score. Just subtract the detractor
                percentage from the promoter percentage.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s know one thing: the NPS survey also collects passive
                scores, but they are not measured.
              </p>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt=" service desk metrics NPS"
              />
              <h3 className="font-section-sub-header-small-home">
                4. Service level agreement or SLA
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                As per ITIL, SLA or service level agreement compliance
                thoroughly explains how to provide service and meet user
                expectations.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Service desks must maintain a specific timeframe based on SLA to
                address and resolve issues. If a service is denied within this
                timeframe, it is known as a violation of SLA, which also
                compromises user experience.
              </p>
              <h4 className="font-section-normal-text-medium">
                How to calculate the SLA rate:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Divide the number of tickets resolved by the number of tickets
                received by your service desk and multiply it by 100 to get a
                percentage. Forget not to pick the same period for the
                calculation.
              </p>
              <img
                src={section_9}
                className="blog_image_top_bt"
                alt="Service desk KPIs SLA rate "
              />
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Agent Productivity and Workload Metrics
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Service desk KPIs or metrics comprise agent productivity and
                workload to determine how agents exert effort to manage tickets
                and deliver service in real time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                In this section, we have as many as five sub-categories to
                determine agent productivity and areas of improvement.
              </p>
              <h3 className="font-section-sub-header-small-home">
                1. Agent utilization
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                In the service desk domain, an agent's time after service
                delivery out of his total available work time is known as agent
                utilization.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Agent utilization rates are essential service desk metrics. They
                can accurately indicate when to fix service desk problems or
                provide adequate resources for a high-performance service desk.
              </p>

              <h4 className="font-section-normal-text-medium">
                How to calculate the agent utilization rate:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Divide an agent's time for a request resolution by the total
                work time in a day. Multiply it by 100 to get a percentage.
              </p>

              <img
                src={section_10}
                className="blog_image_top_bt"
                alt="service desk KPIs agent utilization rate "
              />
              <h3 className="font-section-sub-header-small-home">
                2. First contact resolution (FCR)
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a service desk KPI, FCR is a crucial indicator of a service
                desk’s performance and success.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In this scenario, you must monitor how effectively your service
                desk resolves the number of requests in the first stage without
                escalating those issues to the next tier or causing them to
                recur.
              </p>
              <h4 className="font-section-normal-text-medium">
                How to calculate the FCR rate:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Divide the number of requests resolved in the initial stage by
                the number of requests received and multiply it by 100.
              </p>

              <img
                src={section_11}
                className="blog_image_top_bt"
                alt="service desk KPIs FCR
                "
              />
              <h3 className="font-section-sub-header-small-home">
                3. First Response Time (FRT)
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                For a service desk or IT help desk, it is mandatory to respond
                to user requests as early as possible.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Late response can impact a user’s experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a critical service desk metric, FRT can depict a scenario in
                which the service desk must track the time between a request
                raised and a response made. This is known as FRT.
              </p>
              <h4 className="font-section-normal-text-medium">
                How to calculate FRT:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Calculating the FRT rate is easy. Just subtract the time a
                service desk attended a ticket from the time a user raised a
                request.
              </p>

              <img
                src={section_12}
                className="blog_image_top_bt"
                alt="service desk KPIs FRT"
              />
              <h3 className="font-section-sub-header-small-home">
                4. Time to Resolution (TTR)
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a service desk manager, considering the time to resolution or
                TTR is also very critical to determining the success of your
                service desk.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                TTR is a service desk KPI that helps you gauge how long your
                service desk takes from ticket initiation to response and
                resolution.
              </p>
              <h4 className="font-section-normal-text-medium">
                How to calculate TTR:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Subtract the time your service desk receives a ticket from when
                it resolves the issue.
              </p>
              <img
                src={section_13}
                className="blog_image_top_bt"
                alt="service desk KPIs TTR"
              />
              <h3 className="font-section-sub-header-small-home">
                5. Average Time to Handle (ATH)
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                ATH refers to a service desk metric that helps detect the
                average time a service desk spends handling requests.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                It can include communications, retrieving information, analysis,
                and everything necessary to address an issue.
              </p>
              <h4 className="font-section-normal-text-medium">
                How to calculate AHT:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Divide the time you spend handling the number of tickets your
                service desk receives in a specific timeframe.
              </p>
              <img
                src={section_14}
                className="blog_image_top_bt"
                alt="service desk KPIs AH"
              />
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How to improve IT service desk metrics or KPIs
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Service desk metrics or KPIs{" "}
                </span>{" "}
                are good for determining what you need to improve user response
                and service delivery.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As you check on these metrics frequently or quarterly, you will
                likely detect loopholes your service desk creates and make
                changes to fix them.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Prioritize what your users want:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Often, users ignore using service desk tools. Instead, they use
                legacy mediums to interact and get help. This usually does not
                help.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Users face challenges due to common workplace hurdles such as
                password resets, account unlocks, and user provisions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Prioritize these service requests and implement an omnichannel
                mechanism to deliver an elevated user experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                An occasional survey lets you collect data and know what your
                users prefer to solve their daily problems.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Implement self-service portal:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/leveraging-the-value-of-ai-self-service-workativ">
                  self-service portal
                </a>{" "}
                is a widely used tool for service desk users to raise queries
                and get resolutions effectively.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                If you have none for your team, you can leverage this tool and
                allow effective automation that helps with common service desk
                problems.
              </p>

              <h3 className="font-section-sub-header-small-home">
                Provide accurate knowledge resources:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A service desk or IT support always relies on rich knowledge
                articles to fetch accurate information to get help or provide
                help.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Ensure you update your knowledge articles whenever a new or
                unique solution arrives.
              </p>

              <h3 className="font-section-sub-header-small-home">
                Use Generative AI or automation for user engagement and
                adoption:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Make it easy for your users to absorb information as quickly and
                accurately as possible so that they can efficiently find what
                they need to solve a problem.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-support-human">
                  Generative AI-based automation
                </a>{" "}
                can help consolidate information and lessen the time to apply
                knowledge for problem resolution.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section8">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How Workativ is committed to providing benefits to improve your
                IT service desk metrics
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Regarding improving service desk metrics or IT help desk KPIs,
                Workativ meets business objectives in delivering user
                satisfaction and increasing engagement.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Overall, IT leaders can deliver customer services on time and
                ensure success.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Here’s how:
              </p>

              <ul className="pl-4 mb-2 float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-sub-header-small-home ">
                  Faster response to user queries through knowledge AI
                  self-service:
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                Workativ embeds{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI — an LLM-powered technology
                </a>{" "}
                within its conversational AI platform to allow users to find
                information quickly and conveniently.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                As a result, service desk managers can reduce wait times and
                consequently minimize the chance of ticket escalation to the
                agents.
              </p>
              <ul className="pl-4 mb-2 float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-sub-header-small-home ">
                  Custom automated workflows with domain-specific use cases
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                You can unlikely work with generic use cases that most IT
                ecosystems usually involve. Instead, Workativ allows you to
                create domain-specific workflows to adjust to your business
                needs and allow your users to seek the exemplary service desk
                help they need.
              </p>

              <ul className="pl-4 mb-2 float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-sub-header-small-home ">
                  Wider adoption of self-service portal to score more on CSAT
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Workativ
                </span>{" "}
                enables a seamless sync of its{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-channels">
                  conversational AI platform with communication channels such as
                  Slack or MS Teams.{" "}
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                This allows users to accelerate the pace of raising requests and
                get help without the need to learn a new tool — users already
                know how to use Teams or Slack as their preferred workspace
                platform.
              </p>
              <ul className="pl-4 mb-2 float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-sub-header-small-home ">
                  No-code platform to get started fast
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Workativ
                </span>{" "}
                is a no-code conversational AI platform. Unlike conventional
                ITSM platforms, which comprise several modules for different
                business operations, Workativ is easy to implement based on use
                cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                Workativ requires no steep learning curve as it requires working
                with traditional ITSM platforms, thus reducing developer costs
                and encouraging faster time to market.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Want to improve your service desk metrics or KPIs? Workativ can
                help.&nbsp;
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  Schedule a demo today.{" "}
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section9">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h3 className="font-section-sub-header-small">
                1. What are IT help desk metrics?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Help desk metrics are measuring attributes for help desk support
                provided that helps find performance gaps and strategize to
                optimize IT support.
              </p>
              <h3 className="font-section-sub-header-small">
                2. What is the difference between service desk metrics and KPIs?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Help desk metrics are specific performance measurements for help
                desk support provided, such as average ticket resolution time or
                average handle time. A KPI is a broader measure that aligns with
                organizational goals, such as improved customer satisfaction.
              </p>
              <h3 className="font-section-sub-header-small">
                3. Why are IT service desk metrics important?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                IT service desk metrics are crucial for understanding service
                desk performance, identifying areas for improvement, setting
                goals, and demonstrating the service desk's value to the
                organization.
              </p>
              <h3 className="font-section-sub-header-small">
                4. How can I improve customer satisfaction (CSAT) for my service
                desk?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Implement a self-service portal and provide accurate knowledge
                resources to improve CSAT, reduce ticket resolution times,
                increase first-contact resolution rates, and ensure timely
                responses to user inquiries.
              </p>
              <h3 className="font-section-sub-header-small">
                5. How can Workativ help improve service desk metrics?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  {" "}
                  Workativ's AI-powered platform
                </a>{" "}
                can enhance service desk performance by automating routine
                tasks, improving knowledge management, and providing insights
                into user behavior. This can increase efficiency, reduce ticket
                volumes, and increase customer satisfaction.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. What are help desk or IT Service Desk KPIs or Metrics?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Are help desk metrics and key performance indicators the
                    same?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Why are IT service desk metrics important for your
                    business?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. Incident Management Metrics
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. Service Request Metrics
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. Agent Productivity and Workload Metrics
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. How to improve IT service desk metrics or KPIs
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section8"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section8"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section8")}
                  >
                    8. How Workativ is committed to providing benefits to
                    improve your IT service desk metrics
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section9"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section9"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section9")}
                  >
                    9. FAQs
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Small or big — companies’ success relies upon{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/ai-it-service-management">
                  IT service management.
                </a>{" "}
                Simultaneously, the success of ITSM significantly depends upon
                tracking and working on{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  IT service desk metrics
                </span>{" "}
                — or, to essentially,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  measure service desk KPIs.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is essential to track a variety of IT service desk KPIs or
                Key Performance Indicators to meet service desk KPIs and meet
                stakeholders' expectations or overall business goals.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-automation">
                  service desk
                </a>{" "}
                allows you to implement effective steps to manage service
                delivery.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Conversely, capturing service desk metrics or KPIs helps you
                gauge IT support teams’ performance and implement best
                practices.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s learn what these essential KPIs of Service Desk Metrics
                are.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                What are help desk metrics?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                This is a simple way to look at how your service desk performs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To understand this, you would probably consider some attributes
                that contribute to elevating your service desk’s performance and
                user satisfaction.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A simple definition would be: help desk metrics are measuring
                attributes to examine the performance and effectiveness of the
                service desk towards customer service or employee support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                These KPIs provide efficiency and effectiveness metrics,
                allowing organizations to detect and improve performance gaps to
                optimize{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/transform-service-desk-with-it-chatbot">
                  service desk operations.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                They are indicators for{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/transform-service-desk-with-it-chatbot">
                  service desk software
                </a>{" "}
                to help IT managers determine how a service desk efficiently
                aligns with the business objectives by delivering services for
                users to solve problems and improve uptime.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Considering{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  time to resolution
                </span>{" "}
                (TTR) as a small example, it could act as a service desk metric
                or KPI for IT managers to understand what time a service desk
                takes to resolve an issue. Based on the outcomes, an IT manager
                can suggest improvements.
              </p>
            </div>

            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Are help desk metrics and key performance indicators the same?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Help desk metrics or KPIs may sound similar but vary widely.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In a more specific way, service desk metrics involve performance
                metrics associated with service desks—that is, how well a
                service desk team is performing to assist the requesters.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Or how does a service desk contribute to resolving a particular
                user problem?
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                The other way around, service desk metrics connect dots to
                facilitate KPIs to help gain a bigger organizational goal.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                In a nutshell, we can say — service desk metrics are integral to
                service desk KPIs that bring organizational resilience and
                growth through customer service delivery by adhering to user
                needs and meeting user expectations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Say your{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/helpdesk-problems-solutions">
                  service desk agents improve help desk responses
                </a>{" "}
                by reducing ticket volume for a specific period. This metric
                depicts an organizational goal of resolving as many user
                problems as possible while ensuring user experience.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why are IT service desk metrics important for your business?
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="IT service desk benefits"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Service desk metrics or KPIs are essential to ensure you drive
                business success in several ways.
              </p>

              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Cost reduction:&nbsp;
                  </span>
                  Real-time service delivery confirms that your users get what
                  they want in time and helps reduce costs that are otherwise
                  common in case problems are recurring and frequent.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Improved team productivity:&nbsp;
                  </span>
                  Service desk metrics exhibit the areas for improvement for
                  your teams. IT managers can look into this and work on these
                  areas to bring effective practice that helps boost the team’s
                  performance.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Elevated user experience:&nbsp;
                  </span>
                  IT helps desk metrics can help you visualize what went wrong
                  for your service desk while delivering a service. Eliminating
                  these loopholes can improve service desk performance abilities
                  and meet user experience.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Improved service desk decisions:&nbsp;
                  </span>
                  With service desk metrics, IT managers can improve
                  decision-making and visualize the necessary resources needed
                  to tackle some instances in different scenarios, helping the
                  service desk improve its responses.
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Driving business growth:&nbsp;
                  </span>
                  Based on IT help desk metrics, businesses can get to know
                  where things are not working and implement the best way to
                  tackle adversaries. This allows companies to meet business
                  goals and drive success.
                </li>
              </ul>
            </div>
            <h2 className="font-section-sub-header-bold">
              Crucial service desk metrics or KPIs we should measure
            </h2>
            <img
              src={section_2}
              className="blog_image_top_bt"
              alt="Significant service desk KPIs"
            />
            <p className="font-section-normal-text-testimonials line-height-18 ">
              Service desk or{" "}
              <a href="https://workativ.com/conversational-ai-platform/blog/ai-it-service-management">
                IT service management
              </a>{" "}
              is a broader aspect of IT service operations. As per ITIL, it
              involves a massive area to look upon and correct if these
              attributes are underperforming.
            </p>
            <p className="font-section-normal-text-testimonials line-height-18 ">
              We’ve divided IT help desk metrics into three significant groups
              to make it look easy for you and take the necessary action to
              improve their efficiencies.
            </p>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Incident Management Metrics
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Incident management is crucial, as it combines several metric
                data points to address challenges and ensure an efficient
                service desk.
              </p>
              <h3 className="font-section-sub-header-small-home">
                1. Ticket volume{" "}
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The number of tickets or service requests a service desk handles
                in a particle period is known as ticket volume.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                It signifies how efficiently a service desk team handles service
                requests. If the ticket volume grows, it can directly point to
                service desk inefficiency, indicating that a team may require
                adequate resources for a timely response.
              </p>
              <h4 className="font-section-normal-text-medium">
                How to calculate ticket volume:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                This requires you to monitor a certain period, in which you must
                count how many tickets your service desks receive.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="service desk KPIs Ticket Volume"
              />
              <h3 className="font-section-sub-header-small-home">
                2. Ticket backlog
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The term backlog is easier to understand, which means unresolved
                tickets are getting heaped over due to inattention.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                This shows that a service desk has limited resources or skills
                to handle timely requests, causing ticket backlogs.
              </p>
              <h4 className="font-section-normal-text-medium">
                How to calculate ticket backlog:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Take a specific period for both tickets opened and closed.
                Subtract the open tickets at the beginning and closed tickets at
                the end of the week. This gives you the exact value of how many
                tickets got backlogged.
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt=" Service Desk KPIs Ticket backlog"
              />
              <h3 className="font-section-sub-header-small-home">
                3. Escalation rate
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                With a percentage of tickets being escalated to the next tier
                without the proper resources or skills of the first contact, the
                transfer of tickets to the next tier is known as the escalation
                rate in the service desk ecosystem.
              </p>

              <h4 className="font-section-normal-text-medium">
                How to calculate escalation rate:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Take the number of tickets escalated to the next tier and divide
                it by the total number of tickets received. To get a percentage,
                multiply it by 100.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt=" service desk KPIs Escalation Rate Percentage  "
              />
              <h3 className="font-section-sub-header-small-home">
                4. Incident prioritization
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                An incident could be of many types. However, not every case
                constitutes a severe incident for a service desk, and they can
                get immediate help.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Response and service delivery depend on the severity of a case.
                A service desk generally bases its priority on low, medium,
                high, and critical incidents.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Instead of doing it manually, an AI-based service desk can help
                you gauge incident prioritization and build a strategy for
                tackling incidents.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Service Request Metrics
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                In determining service desk metrics, you can prefer capturing
                data through various attributes of service request metrics. They
                are stated below:
              </p>
              <h3 className="font-section-sub-header-small-home">
                1. Customer satisfaction score (CSAT)
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                One of the best key performance indicators for{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/roi-chatbot-automation-it-service-desk">
                  IT service desk performance
                </a>{" "}
                improvement is CSAT.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                It is not at all rocket science. All it takes is to ask your
                customers or service desk users how they feel about the service
                responses they receive.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Usually, the CSAT metric is captured on a scale of 1 to 5 or 1
                to 10, and the CSAT feedback survey can ask you to choose any
                options from very satisfied to very unsatisfied.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If your CSAT score is higher, your users will be happy with your
                service quality. The opposite is true if your CSAT score is low.
              </p>
              <h4 className="font-section-normal-text-medium">
                How to calculate the CSAT rate:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Calculate CSAT every quarter or month to have enough time to
                improve your customer satisfaction score with your service desk.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Divide the CSAT score you collected by the total customer
                responses and multiply it by 100.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="Service desk KPIs CSAT"
              />
              <h3 className="font-section-sub-header-small-home">
                2. Customer effort score or CES
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The word ‘effort’ in CES says it all.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                How much effort a user needs to exert while trying to solve a
                problem through a service desk is all that is a metric that
                defines customer effort score.
              </p>
              <h4 className="font-section-normal-text-medium">
                How to calculate CES:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Three simple ways constitute how CES must be measured —
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-normal-text-testimonials">
                  Word-based scale
                </li>
                <li className="font-section-normal-text-testimonials">
                  Numerical scale
                </li>
                <li className="font-section-normal-text-testimonials">
                  Sentiment scale
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                On a scale of ‘very easy’ or ‘very difficult’, the customer
                effort score can be measured.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, you can choose any of these methods to calculate CES and
                divide the sum of CES scores by the total responses collected.
                To get a percentage, multiply it by 100.
              </p>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="Service desk KPIs CES"
              />
              <h3 className="font-section-sub-header-small-home">
                3. Net promoter score (NPS)
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is another effective service desk KPI to measure the
                effectiveness of your IT help desk.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With ecommerce interactions, customers are more likely to ask,
                ‘How likely are you eager to recommend our service to others?’
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On a scale of 0-10, which focuses primarily on two key metrics,
                highly and never, are used to know user loyalty and advocacy. In
                the language of NPS, users who recommend your service desk to
                others are recognized as promoters, and those who don’t are
                known as detractors.
              </p>
              <h4 className="font-section-normal-text-medium">
                How to calculate NPS:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is easy to measure the NPS score. Just subtract the detractor
                percentage from the promoter percentage.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s know one thing: the NPS survey also collects passive
                scores, but they are not measured.
              </p>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt=" service desk metrics NPS"
              />
              <h3 className="font-section-sub-header-small-home">
                4. Service level agreement or SLA
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                As per ITIL, SLA or service level agreement compliance
                thoroughly explains how to provide service and meet user
                expectations.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Service desks must maintain a specific timeframe based on SLA to
                address and resolve issues. If a service is denied within this
                timeframe, it is known as a violation of SLA, which also
                compromises user experience.
              </p>
              <h4 className="font-section-normal-text-medium">
                How to calculate the SLA rate:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Divide the number of tickets resolved by the number of tickets
                received by your service desk and multiply it by 100 to get a
                percentage. Forget not to pick the same period for the
                calculation.
              </p>
              <img
                src={section_9}
                className="blog_image_top_bt"
                alt="Service desk KPIs SLA rate "
              />
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Agent Productivity and Workload Metrics
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Service desk KPIs or metrics comprise agent productivity and
                workload to determine how agents exert effort to manage tickets
                and deliver service in real time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                In this section, we have as many as five sub-categories to
                determine agent productivity and areas of improvement.
              </p>
              <h3 className="font-section-sub-header-small-home">
                1. Agent utilization
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                In the service desk domain, an agent's time after service
                delivery out of his total available work time is known as agent
                utilization.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Agent utilization rates are essential service desk metrics. They
                can accurately indicate when to fix service desk problems or
                provide adequate resources for a high-performance service desk.
              </p>

              <h4 className="font-section-normal-text-medium">
                How to calculate the agent utilization rate:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Divide an agent's time for a request resolution by the total
                work time in a day. Multiply it by 100 to get a percentage.
              </p>

              <img
                src={section_10}
                className="blog_image_top_bt"
                alt="service desk KPIs agent utilization rate "
              />
              <h3 className="font-section-sub-header-small-home">
                2. First contact resolution (FCR)
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a service desk KPI, FCR is a crucial indicator of a service
                desk’s performance and success.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In this scenario, you must monitor how effectively your service
                desk resolves the number of requests in the first stage without
                escalating those issues to the next tier or causing them to
                recur.
              </p>
              <h4 className="font-section-normal-text-medium">
                How to calculate the FCR rate:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Divide the number of requests resolved in the initial stage by
                the number of requests received and multiply it by 100.
              </p>

              <img
                src={section_11}
                className="blog_image_top_bt"
                alt="service desk KPIs FCR
                "
              />
              <h3 className="font-section-sub-header-small-home">
                3. First Response Time (FRT)
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                For a service desk or IT help desk, it is mandatory to respond
                to user requests as early as possible.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Late response can impact a user’s experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a critical service desk metric, FRT can depict a scenario in
                which the service desk must track the time between a request
                raised and a response made. This is known as FRT.
              </p>
              <h4 className="font-section-normal-text-medium">
                How to calculate FRT:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Calculating the FRT rate is easy. Just subtract the time a
                service desk attended a ticket from the time a user raised a
                request.
              </p>

              <img
                src={section_12}
                className="blog_image_top_bt"
                alt="service desk KPIs FRT"
              />
              <h3 className="font-section-sub-header-small-home">
                4. Time to Resolution (TTR)
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a service desk manager, considering the time to resolution or
                TTR is also very critical to determining the success of your
                service desk.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                TTR is a service desk KPI that helps you gauge how long your
                service desk takes from ticket initiation to response and
                resolution.
              </p>
              <h4 className="font-section-normal-text-medium">
                How to calculate TTR:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Subtract the time your service desk receives a ticket from when
                it resolves the issue.
              </p>
              <img
                src={section_13}
                className="blog_image_top_bt"
                alt="service desk KPIs TTR"
              />
              <h3 className="font-section-sub-header-small-home">
                5. Average Time to Handle (ATH)
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                ATH refers to a service desk metric that helps detect the
                average time a service desk spends handling requests.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                It can include communications, retrieving information, analysis,
                and everything necessary to address an issue.
              </p>
              <h4 className="font-section-normal-text-medium">
                How to calculate AHT:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Divide the time you spend handling the number of tickets your
                service desk receives in a specific timeframe.
              </p>
              <img
                src={section_14}
                className="blog_image_top_bt"
                alt="service desk KPIs AH"
              />
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How to improve IT service desk metrics or KPIs
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Service desk metrics or KPIs{" "}
                </span>{" "}
                are good for determining what you need to improve user response
                and service delivery.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                As you check on these metrics frequently or quarterly, you will
                likely detect loopholes your service desk creates and make
                changes to fix them.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Prioritize what your users want:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Often, users ignore using service desk tools. Instead, they use
                legacy mediums to interact and get help. This usually does not
                help.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Users face challenges due to common workplace hurdles such as
                password resets, account unlocks, and user provisions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Prioritize these service requests and implement an omnichannel
                mechanism to deliver an elevated user experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                An occasional survey lets you collect data and know what your
                users prefer to solve their daily problems.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Implement self-service portal:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/leveraging-the-value-of-ai-self-service-workativ">
                  self-service portal
                </a>{" "}
                is a widely used tool for service desk users to raise queries
                and get resolutions effectively.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                If you have none for your team, you can leverage this tool and
                allow effective automation that helps with common service desk
                problems.
              </p>

              <h3 className="font-section-sub-header-small-home">
                Provide accurate knowledge resources:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A service desk or IT support always relies on rich knowledge
                articles to fetch accurate information to get help or provide
                help.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Ensure you update your knowledge articles whenever a new or
                unique solution arrives.
              </p>

              <h3 className="font-section-sub-header-small-home">
                Use Generative AI or automation for user engagement and
                adoption:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Make it easy for your users to absorb information as quickly and
                accurately as possible so that they can efficiently find what
                they need to solve a problem.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-support-human">
                  Generative AI-based automation
                </a>{" "}
                can help consolidate information and lessen the time to apply
                knowledge for problem resolution.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section8">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How Workativ is committed to providing benefits to improve your
                IT service desk metrics
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Regarding improving service desk metrics or IT help desk KPIs,
                Workativ meets business objectives in delivering user
                satisfaction and increasing engagement.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Overall, IT leaders can deliver customer services on time and
                ensure success.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Here’s how:
              </p>

              <ul className="pl-4 mb-2 float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-sub-header-small-home ">
                  Faster response to user queries through knowledge AI
                  self-service:
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                Workativ embeds{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI — an LLM-powered technology
                </a>{" "}
                within its conversational AI platform to allow users to find
                information quickly and conveniently.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                As a result, service desk managers can reduce wait times and
                consequently minimize the chance of ticket escalation to the
                agents.
              </p>
              <ul className="pl-4 mb-2 float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-sub-header-small-home ">
                  Custom automated workflows with domain-specific use cases
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                You can unlikely work with generic use cases that most IT
                ecosystems usually involve. Instead, Workativ allows you to
                create domain-specific workflows to adjust to your business
                needs and allow your users to seek the exemplary service desk
                help they need.
              </p>

              <ul className="pl-4 mb-2 float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-sub-header-small-home ">
                  Wider adoption of self-service portal to score more on CSAT
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Workativ
                </span>{" "}
                enables a seamless sync of its{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-channels">
                  conversational AI platform with communication channels such as
                  Slack or MS Teams.{" "}
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                This allows users to accelerate the pace of raising requests and
                get help without the need to learn a new tool — users already
                know how to use Teams or Slack as their preferred workspace
                platform.
              </p>
              <ul className="pl-4 mb-2 float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-sub-header-small-home ">
                  No-code platform to get started fast
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Workativ
                </span>{" "}
                is a no-code conversational AI platform. Unlike conventional
                ITSM platforms, which comprise several modules for different
                business operations, Workativ is easy to implement based on use
                cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                Workativ requires no steep learning curve as it requires working
                with traditional ITSM platforms, thus reducing developer costs
                and encouraging faster time to market.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Want to improve your service desk metrics or KPIs? Workativ can
                help.&nbsp;
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  Schedule a demo today.{" "}
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section9">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                FAQs
              </h2>
              <h3 className="font-section-sub-header-small">
                1. What are IT help desk metrics?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Help desk metrics are measuring attributes for help desk support
                provided that helps find performance gaps and strategize to
                optimize IT support.
              </p>
              <h3 className="font-section-sub-header-small">
                2. What is the difference between service desk metrics and KPIs?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Help desk metrics are specific performance measurements for help
                desk support provided, such as average ticket resolution time or
                average handle time. A KPI is a broader measure that aligns with
                organizational goals, such as improved customer satisfaction.
              </p>
              <h3 className="font-section-sub-header-small">
                3. Why are IT service desk metrics important?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                IT service desk metrics are crucial for understanding service
                desk performance, identifying areas for improvement, setting
                goals, and demonstrating the service desk's value to the
                organization.
              </p>
              <h3 className="font-section-sub-header-small">
                4. How can I improve customer satisfaction (CSAT) for my service
                desk?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Implement a self-service portal and provide accurate knowledge
                resources to improve CSAT, reduce ticket resolution times,
                increase first-contact resolution rates, and ensure timely
                responses to user inquiries.
              </p>
              <h3 className="font-section-sub-header-small">
                5. How can Workativ help improve service desk metrics?
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  {" "}
                  Workativ's AI-powered platform
                </a>{" "}
                can enhance service desk performance by automating routine
                tasks, improving knowledge management, and providing insights
                into user behavior. This can increase efficiency, reduce ticket
                volumes, and increase customer satisfaction.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/roi-chatbot-automation-it-service-desk"
              className="font-section-normal-text-testimonials"
            >
              The ROI of Using Chatbot and Automation with Your IT Service Desk​
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/ai-it-service-management"
              className="font-section-normal-text-testimonials"
            >
              AI in ITSM: Unlocking the Value of AI for IT Service Management
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/onboarding-challenges-it-service-desk-face-with-new-agents"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Onboarding Challenges IT Service Desk Face with New Agents
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
